import React from 'react';
import { NotificationManager } from 'react-notifications';
import { useSearchParams } from 'react-router-dom';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Alert,
    Button,
    Container,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    Input
} from '@mui/material';

import { passwordReset } from '../../api/Auth';



const PasswordReset = (props) => {
    const [searchParams] = useSearchParams();

    const [showPassword, setShowPassword] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [failedPasswordReset, setFailedPasswordReset] = React.useState(false);
    const [sucessfulPasswordReset, setSucessfulPasswordReset] = React.useState(false);
    const [failedPasswordResetMsg, setFailedPasswordResetMsg] = React.useState('');

    const [password, setPassword] = React.useState('');
    const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
    const [enableSubmit, setEnableSubmit] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleSubmitForm = (event) => {
        event.preventDefault();
        const token = searchParams.get('token');
        setLoading(true);
        setFailedPasswordReset(false);
        passwordReset(token, password)
            .then(() => {
                setSucessfulPasswordReset(true);
                setLoading(false);
            }).catch((err) => {
                setSucessfulPasswordReset(false);
                if (err.response.status === 404) {
                    setFailedPasswordResetMsg('This password reset link has expired. Please request to reset password again.');
                    setFailedPasswordReset(true);
                } else if (err.response.status === 400) {
                    const errorMsgs = Object.keys(err.response.data).flatMap((key) => {
                        return err.response.data[key];
                    });
                    setFailedPasswordResetMsg(errorMsgs[0]);
                    setFailedPasswordReset(true);
                } else {
                    NotificationManager.error('Something went wrong, please contact support if this issue persist.', 'Error!', 5000);
                    console.error(err.response);
                }
                setLoading(false);
            });
    }

    React.useEffect(() => {
        setEnableSubmit(password.length >= 8 && password === passwordConfirmation);
    }, [password, passwordConfirmation]);

    return (
        <Container maxWidth='xs' sx={{ margin: '3em auto', textAlign: 'center' }}>
            <form onSubmit={handleSubmitForm}>
                <h1 style={{ textAlign: 'center' }}>Reset Password</h1>
                {failedPasswordReset && <Alert severity='error'>{failedPasswordResetMsg}</Alert>}
                {sucessfulPasswordReset && <Alert severity='success'>Your password has been reset.</Alert>}
                {!sucessfulPasswordReset &&
                    <>
                        <FormControl fullWidth sx={{ marginTop: '1em' }} variant='standard'>
                            <InputLabel>New Password</InputLabel>
                            <Input
                                error={failedPasswordReset}
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            edge='end'
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label='New Password'
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ marginTop: '1em' }} variant='standard'>
                            <InputLabel>Confirm New Password</InputLabel>
                            <Input
                                error={failedPasswordReset}
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            edge='end'
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label='Confirm New Password'
                                value={passwordConfirmation}
                                onChange={(event) => setPasswordConfirmation(event.target.value)}
                            />
                        </FormControl>
                        <LoadingButton disabled={!enableSubmit} loading={loading} sx={{ width: '100%', marginTop: '2em' }} variant='contained' type='submit'>Change Password</LoadingButton>
                    </>
                }
                {sucessfulPasswordReset && <Button variant='contained' href='/login' fullWidth sx={{ marginTop: '1em' }}>Login</Button>}
            </form>
        </Container>
    )
}

export default PasswordReset;