import React from 'react';

import ImageIcon from '@mui/icons-material/Image';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const FilesList = ({ files, setSelectedFile, selectedFile, secondaryAction, ...props }) => {
   
    return (
        <List sx={{ width: '100%', maxHeight: '300px', overflow: 'auto', m: 1, ...props.sx }}>
            {files.map((file, index) => (
                <ListItem
                    key={file.id}
                    secondaryAction={secondaryAction ? secondaryAction(file) : null}
                    disablePadding
                >
                    <ListItemButton
                        onClick={() => setSelectedFile(index)}
                        selected={index === selectedFile}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <ImageIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={file.name} sx={{ overflow: 'hidden' }} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
}

export default FilesList;
