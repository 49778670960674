import axios from 'axios';
import { NotificationManager } from 'react-notifications';

import { getAccessToken, getRefreshToken, loggout, refresh } from './Auth';
import { getMidlBaseUrl } from './Conf';

const axiosInstance = axios.create({
    headers: {
        Authorization: `Bearer ${getAccessToken()}`
    }
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (!error.response && error.request) {
            // The request was made but no response was received
            const errorMsg = 'No response from service, please contact support if this issue persist.';
            NotificationManager.error(errorMsg, 'ERROR', 5000);
        } else if (error.response.status === 401 &&
            error.response.data.code === 'token_not_valid') {
            // Token invalid
            const originalRequest = error.config;

            const refreshToken = getRefreshToken();
            if (refreshToken) {
                const refreshTokenData = JSON.parse(Buffer.from(refreshToken.split('.')[1], 'base64'));
                // exp date in token is expressed in seconds, while now() returns milliseconds:
                const now = Math.ceil(Date.now() / 1000);
                if (refreshTokenData.exp > now) {
                    return refresh()
                        .then((response) => {
                            axiosInstance.defaults.headers['Authorization'] = `Bearer ${getAccessToken()}`;
                            originalRequest.headers['Authorization'] = `Bearer ${getAccessToken()}`;
                            return axiosInstance(originalRequest);
                        })
                        .catch(err => {
                            console.error(err);
                            loggout();
                            window.location.href = '/login/';
                        });
                } else {
                    console.log('Refresh token is expired', refreshTokenData.exp, now);
                    loggout();
                    window.location.href = '/login/';
                }
            } else {
                console.log('Refresh token not available.');
                loggout();
                window.location.href = '/login/';
            }
        } else {
            if (error.response.status >= 500) {
                const errorMsg = 'Something went wrong, please contact support if this issue persist.';
                NotificationManager.error(errorMsg, 'ERROR', 5000);
            } else {
                const errorMsgs = Object.keys(error.response.data).flatMap((key) => {
                    return error.response.data[key];
                });
                NotificationManager.error(errorMsgs[0], 'ERROR', 5000);
            }
        }
        // specific error handling done elsewhere
        return Promise.reject(error);
    }
);


export const getOrders = () => {
    return axiosInstance
        .get(getMidlBaseUrl() + '/api/orders/')
        .then((resp) => {
            return resp.data;
        });
}

export const getOrder = (id) => {
    return axiosInstance
        .get(getMidlBaseUrl() + `/api/orders/${id}/`)
        .then((resp) => {
            return resp.data;
        });
}


export const createOrder = (data) => {
    return axiosInstance
        .post(getMidlBaseUrl() + `/api/orders/`, data)
        .then((resp) => {
            return resp.data;
        });
}


export const updateOrder = (id, data) => {
    return axiosInstance
        .patch(getMidlBaseUrl() + `/api/orders/${id}/`, data)
        .then((resp) => {
            return resp.data;
        });
}


export const submitOrder = (id) => {
    return axiosInstance
        .post(getMidlBaseUrl() + `/api/orders/${id}/submit/`)
        .then((resp) => {
            return resp.data;
        });
}


export const uploadFile = (data) => {
    var formData = new FormData();
    formData.append('file', data.file);
    return axiosInstance
        .post(getMidlBaseUrl() + '/api/files/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((resp) => {
            return resp.data;
        });
}

export const getFiles = () => {
    return axiosInstance
        .get(getMidlBaseUrl() + '/api/files/')
        .then((resp) => {
            return resp.data;
        });
}

export const getCheckoutOrder = (id) => {
    return axios
        .get(getMidlBaseUrl() + `/api/order_confimations/${id}/`)
        .then((resp) => {
            return resp.data;
        });
}


export const downloadFile = (order_id, file_id) => {
    return axios
        .get(getMidlBaseUrl() + `/api/order_confimations/${order_id}/download/`,
            { params: { file: file_id }, responseType: 'blob' })
        .then((resp) => {
            return resp.data;
        });
}


export const downloadAll = (order_id) => {
    return axios
        .get(getMidlBaseUrl() + `/api/order_confimations/${order_id}/download_all/`, { responseType: 'blob' })
        .then((resp) => {
            return resp.data;
        });
}

export const getStripeLoginLink = () => {
    return axiosInstance
        .get(getMidlBaseUrl() + '/api/account/stripe_login_link/')
        .then((resp) => {
            return resp.data;
        });
}


export const getStripeAccountLink = () => {
    return axiosInstance
        .get(getMidlBaseUrl() + '/api/account/stripe_account_link/')
        .then((resp) => {
            return resp.data;
        });
}

export const getAccount = () => {
    return axiosInstance
        .get(getMidlBaseUrl() + '/api/account/')
        .then((resp) => {
            return resp.data;
        });
}

export const getCustomers = () => {
    return axiosInstance
        .get(getMidlBaseUrl() + '/api/customers/')
        .then((resp) => {
            return resp.data;
        });
}

export const createCustomer = (data) => {
    return axiosInstance
        .post(getMidlBaseUrl() + '/api/customers/', data)
        .then((resp) => {
            return resp.data;
        });
}