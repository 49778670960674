import React from 'react';

import { Container, Typography } from '@mui/material';

const PrivacyPolicy = (props) => {
    return (
        <Container maxWidth='xl' sx={{ margin: '3em auto' }}>
            <Typography variant='h1' gutterBottom>Privacy Policy</Typography>
            <Typography>This Privacy Policy describes how we collect, use, and
                disclose your personal information when you use our website and associated apps, including all pages
                within this website and all pages within any app produced by MIDL (the “Service”). By using our Service,
                you agree to the collection, use, and disclosure of your personal information in accordance with this
                Privacy Policy.</Typography>
            <Typography variant='h2' style={{marginTop: '1em'}}>Information We Collect</Typography>
            <Typography>We collect the following types of information about
                you:</Typography>
            <ol>
                <li>Information you choose to give us, such as your username, first and last name, email address, mobile
                    phone number, and a photo.</li>
                <li>Information we get when you use the Service, such as IP address, browser information, referring/exit
                    pages and URLs, clickstream data, and information about how you interact with links on the website,
                    mobile app, or Service.</li>
                <li>Information we get from third parties, such as social networks or non-affiliated third parties for
                    fraud or safety protection purposes or for marketing purposes.</li>
            </ol>
            <Typography variant='h2' style={{marginTop: '1em'}}>How We Use Your Information</Typography>
            <Typography>We may use the information we collect for various
                purposes, including:</Typography>
            <ul>
                <li >To operate, maintain, and provide you with the features and
                    functionality of the Service, including customer support.</li>
                <li >To develop, operate, improve, deliver, maintain and protect our
                    products and services.</li>
                <li >To send you communications, including by email.</li>
                <li >To monitor and analyze trends and usage.</li>
                <li >To verify your identity and prevent fraud or other unauthorized or
                    illegal activity.</li>
                <li >To store or use information locally on your device.</li>
            </ul>
            <Typography variant='h2' style={{marginTop: '1em'}}>How We Share Your Information</Typography>
            <Typography>We may share information about you in the following
                ways:</Typography>
            <ul>
                <li >With other users, such as your public information and user content you
                    post or send.</li>
                <li >With third-party companies and individuals to facilitate our Service or
                    perform Service-related services.</li>
                <li >With advertisers as aggregated or anonymized information.</li>
                <li >With third parties for legal reasons, such as to comply with any valid
                    legal process, governmental request, or applicable law, rule or regulation.</li>
                <li >With third parties as part of a merger or acquisition.</li>
                <li >We may use and repost your posts for marketing purposes.</li>
            </ul>
            <Typography variant='h2' style={{marginTop: '1em'}}>Payment Provider</Typography>
            <Typography>We use a third-party payment processor to process
                payments made to us. In connection with the processing of such payments, we do not retain any personally
                identifiable information or any financial information such as credit card numbers. Rather, all such
                information is provided directly to our third-party processor, Stripe, whose use of your personal
                information is governed by their privacy policy.</Typography>
            <Typography variant='h2' style={{marginTop: '1em'}}>Links to Other Sites</Typography>
            <Typography>Our Service may contain links to other sites. We have
                no control over, and assume no responsibility for, the content, privacy policies, or practices of any
                third-party sites or services.</Typography>
            <Typography variant='h2' style={{marginTop: '1em'}}>Children&#x27;s Privacy</Typography>
            <Typography>Our Services do not address anyone under the age of
                13. We do not knowingly collect personal identifiable information from children under 13. If you are a
                parent or guardian and you are aware that your child has provided us with personal information, please
                contact us so that we will be able to do necessary actions.</Typography>
            <Typography variant='h2' style={{marginTop: '1em'}}>Changes to This Privacy Policy</Typography>
            <Typography>We may update our Privacy Policy from time to time. We
                will notify you of any changes by posting the new Privacy Policy on this page. These changes are
                effective immediately, after they are posted on this page.</Typography>
            <Typography variant='h2' style={{marginTop: '1em'}}>Contact Us</Typography>
            <Typography>If you have any questions or suggestions about our
                Privacy Policy, please contact us at developer@midlpay.com</Typography>
        </Container>
    );
}
export default PrivacyPolicy;
