import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import DownloadIcon from '@mui/icons-material/Download';
import ImageIcon from '@mui/icons-material/Image';
import {
    Alert,
    AlertTitle, Avatar,
    Button,
    CircularProgress, Container, Divider, IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText, Typography
} from '@mui/material';
import FileDisplay from '../common/FileDisplay';

import { downloadAll, downloadFile, getCheckoutOrder } from '../../api/Midl';


const statusLable = {
    'Open':
        <Alert variant="filled" severity="info" sx={{ width: '100%', textAlign: 'left', margin: '1em 0' }}>
            <AlertTitle>Awaiting Payment</AlertTitle>
            Files will be available for download once payment is complete.
        </Alert>,
    'Accepted':
        <Alert variant="filled" severity="success" sx={{ width: '100%', textAlign: 'left', marginBottom: '1em' }}>
            <AlertTitle>Invoice Paid</AlertTitle>
            Files are available for download.
        </Alert>
};

const Checkout = (props) => {
    const params = useParams();
    const [order, setOrder] = useState(null);

    const loadOrder = (id) => {
        getCheckoutOrder(id)
            .then((orderData) => {
                setOrder(orderData);
            });
    }

    const handlePay = () => {
        window.open(order.hosted_invoice_url, '_blank', 'noreferrer')
    }
    const handleDownloadFile = (file) => {
        downloadFile(params.id, file.id)
            .then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response);

                // create 'a' HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', file.name); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up 'a' element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });
    }

    const handleDownloadAll = () => {
        downloadAll(params.id)
            .then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response);

                // create 'a' HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', order.name + ".zip"); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up 'a' element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });
    }

    React.useEffect(() => {
        loadOrder(params.id);
    }, []);

    if (!order) {
        return (<CircularProgress />);
    }

    return (
        <Container maxWidth='sm' sx={{ margin: '3em auto', textAlign: 'center' }}>
            {statusLable[order.status]}
            <Typography variant='h1'>{order.name}</Typography>
            <Typography variant='h2' gutterBottom>From: {order.owner.stripe_name}</Typography>

            <FileDisplay
                files={order.files}
                canEdit={false}
                preview={true}
                secondaryAction={(file) => {
                    return (<IconButton disabled={order.status !== 'Accepted'} edge='end' aria-label='download' onClick={(event) => handleDownloadFile(file)}>
                        <DownloadIcon />
                    </IconButton>);
                }}
            />

            {order.status !== 'Accepted' &&
                <>
                    <Button variant='contained' size="large" style={{ display: 'block', margin: '1em auto', width: '50%' }} onClick={handlePay}>View and Pay Invoice</Button>
                    <Typography color='secondary.main'>Please <b>refresh this page once payment is complete</b>.</Typography>
                    <Typography color='secondary.main'>Upon payment you will recieve an email with a download link.</Typography>
                </>}
            {order.status === 'Accepted' &&
                <>
                    <Button variant='contained' size="large" style={{ display: 'block', margin: '1em auto', width: '50%' }} onClick={handleDownloadAll}>Download All</Button>
                    <Button size="large" style={{ display: 'block', margin: '1em auto', width: '50%' }} onClick={handlePay}>View Invoice</Button>
                </>}

        </Container>
    );
}
export default Checkout;