import Box from '@mui/material/Box';
import React from 'react';



const FilePreview = ({ file_url, ...props }) => {

    if (!file_url) {
        return (<></>);
    }

    return (
        <div style={{ display: 'flex', height: '500px', width: '500px', alignItems: 'center', justifyContent: 'center', margin: 'auto'}}>
            <Box
                key={file_url}
                component='img'
                sx={{
                    display: 'block',
                    maxHeight: '100%',
                    maxWidth: '100%',
                    borderRadius: 2,
                }}
                src={file_url}
            />
        </div>
    );
}

export default FilePreview;
