import axios from 'axios';

import { getMidlBaseUrl } from './Conf';


export const getAccessToken = () => {
    return localStorage.getItem('access-token');
}

export const getRefreshToken = () => {
    return localStorage.getItem('refresh-token');
}

export const getIsLoggedIn = () => {
    return !!getAccessToken() && !!getRefreshToken();
}

export const loggin = (email, password) => {
    return axios.post(getMidlBaseUrl() + '/api/token/', { email, password })
        .then((response) => {
            localStorage.setItem('access-token', response.data.access);
            localStorage.setItem('refresh-token', response.data.refresh);
            return true;
        })
        .catch((error) => {
            console.error(error);
            return false;
        });
}

export const refresh = () => {
    return axios.post(getMidlBaseUrl() + '/api/token/refresh/', { refresh: getRefreshToken() })
        .then((response) => {
            localStorage.setItem('access-token', response.data.access);
            return true;
        })
        .catch((error) => {
            console.error(error);
            loggout();
            throw error;
        });
}

export const passwordReset = (token, password) => {
    return axios.post(getMidlBaseUrl() + '/api/account/password_reset/confirm/', { token: token, password: password })
        .then((response) => {
            return true;
        })
        .catch((error) => {
            console.error(error);
            throw error;
        });
}

export const requestPasswordReset = (email) => {
    return axios.post(getMidlBaseUrl() + '/api/account/password_reset/', { email: email })
        .then((response) => {
            return true;
        })
        .catch((error) => {
            console.error(error);
            throw error;
        });
}

export const register = (email, first_name, last_name, password, token) => {
    return axios.post(getMidlBaseUrl() + '/api/account/', { email: email, first_name: first_name, last_name: last_name, password: password, token: token })
        .then((response) => {
            return true;
        })
        .catch((error) => {
            console.error(error);
            throw error;
        });
}

export const verify = (email, token) => {
    return axios.post(getMidlBaseUrl() + '/api/account/verify_email/', { email: email, token: token })
        .then((response) => {
            return true;
        })
        .catch((error) => {
            console.error(error);
            throw error;
        });
}

export const loggout = () => {
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
}