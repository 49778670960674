import React from 'react';
import { NotificationManager } from 'react-notifications';

import LoadingButton from '@mui/lab/LoadingButton';
import { Container, TextField, Typography } from '@mui/material';


import { requestPasswordReset } from '../../api/Auth';


const ForgotPassword = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [passwordResetSent, setPasswordResetSent] = React.useState(false);

    const handleSubmitForm = (event) => {
        event.preventDefault();
        const email = event.target.email.value;
        setLoading(true);
        setPasswordResetSent(false);
        requestPasswordReset(email)
            .then((success) => {
                setLoading(false);
                setPasswordResetSent(true);
            }).catch((err) => {
                setLoading(false);
                if (err.response.data) {
                    const errorMsgs = Object.keys(err.response.data).flatMap((key) => {
                        return err.response.data[key];
                    });
                    NotificationManager.error(errorMsgs[0], 'Error!', 5000);
                } else {
                    NotificationManager.error('Something went wrong, please contact support if this issue persist.', 'Error!', 5000);
                    console.error(err.response);
                }
            });
    }

    return (
        <Container maxWidth='xs' sx={{ margin: '3em auto', textAlign: 'center' }}>
            <form onSubmit={handleSubmitForm}>
                <Typography variant='h1' gutterBottom>Forgot Your Password?</Typography>
                {passwordResetSent &&
                    <Typography>If there is an account associated with this email address you will receive a password reset email.</Typography>
                }
                {!passwordResetSent && <>
                    <Typography gutterBottom>Enter the email address you use to sign in to Midl.</Typography>
                    <TextField
                        fullWidth
                        type='email'
                        label='Email'
                        name='email'
                        variant='outlined'
                        margin='normal'
                    />
                    <LoadingButton fullWidth loading={loading} variant='contained' type='submit' sx={{ marginTop: '1em' }}>Reset Password</LoadingButton>
                </>
                }
            </form>
        </Container>
    )
}

export default ForgotPassword;
