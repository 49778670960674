import React from 'react';
import { NotificationManager } from 'react-notifications';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import { Container, FormControl, IconButton, Input, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';

import { register } from '../../api/Auth';


const Register = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [formSubmitted, setFormSubmitted] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [enableSubmit, setEnableSubmit] = React.useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = React.useState('');

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleSubmitForm = (event) => {
        event.preventDefault();
        window.grecaptcha.enterprise.ready(async () => {
            const token = await window.grecaptcha.enterprise.execute('6Ld3bgMpAAAAAMWhumNnLZ4i6JxmFG9dCSvuX8tB', { action: 'REGISTER' });
            const email = event.target.email.value;
            const first_name = event.target.first_name.value;
            const last_name = event.target.last_name.value;
            setLoading(true);
            setFormSubmitted(false);
            register(email, first_name, last_name, password, token)
                .then((success) => {
                    setFormSubmitted(true);
                }).catch((err) => {
                    if (err.response.data) {
                        const errorMsgs = Object.keys(err.response.data).flatMap((key) => {
                            return err.response.data[key];
                        });
                        NotificationManager.error(errorMsgs[0], 'Error!', 5000);
                    } else {
                        NotificationManager.error('Something went wrong, please contact support if this issue persist.', 'Error!', 5000);
                        console.error(err.response);
                    }
                }).finally(() => { setLoading(false) });
        });
    }

    React.useEffect(() => {
        setEnableSubmit(password.length >= 8 && password === passwordConfirmation);
    }, [password, passwordConfirmation]);

    return (
        <Container maxWidth='sm' sx={{ margin: '3em auto', textAlign: 'center' }}>
            <form onSubmit={handleSubmitForm}>
                <Typography variant='h1' gutterBottom>Register for Beta Access</Typography>
                {formSubmitted &&
                    <Typography>Please check your emails for a verification.</Typography>
                }
                {!formSubmitted && <>
                    <Typography gutterBottom>We are onboarding users slowly to ensure you get the support you deserve.</Typography>
                    <Typography gutterBottom>Register and we will let you know when we are ready for you!</Typography>
                    <TextField
                        fullWidth
                        required
                        label='First Name'
                        name='first_name'
                        variant='standard'
                        margin='normal'
                    />
                    <TextField
                        fullWidth
                        required
                        label='Last Name'
                        name='last_name'
                        variant='standard'
                        margin='normal'
                    />
                    <TextField
                        fullWidth
                        required
                        type='email'
                        label='Email'
                        name='email'
                        variant='standard'
                        margin='normal'
                    />
                    <FormControl fullWidth sx={{ marginTop: '1em' }} variant='standard' required>
                        <InputLabel>New Password</InputLabel>
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        edge='end'
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label='New Password'
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ marginTop: '1em' }} variant='standard' required>
                        <InputLabel>Confirm New Password</InputLabel>
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        edge='end'
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label='Confirm New Password'
                            value={passwordConfirmation}
                            onChange={(event) => setPasswordConfirmation(event.target.value)}
                        />
                    </FormControl>
                    <LoadingButton fullWidth loading={loading} variant='contained' type='submit' disabled={!enableSubmit} sx={{ marginTop: '1em' }}>Register</LoadingButton>
                </>
                }
            </form>
        </Container>
    )
}

export default Register;
