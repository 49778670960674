import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AppBar, Button, IconButton, SvgIcon, Toolbar, Stack } from '@mui/material';

import { getIsLoggedIn, loggout } from '../../api/Auth';
import { ReactComponent as Logo } from '../../assets/midl-logo-full-light.svg';

const navbar = () => {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/login');
    }

    const handleLogout = () => {
        loggout();
        navigate('/');
    }

    return (
        <AppBar position="static" sx={{ backgroundColor: 'background.dark' }} elevation={0}>
            <Toolbar  sx={{margin: 'auto', maxWidth: 'xl', width: '100%'}}>
                <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    aria-label='menu'
                    onClick={() => getIsLoggedIn() ? navigate('/dashboard') : navigate('/')}
                >
                    <SvgIcon component={Logo} viewBox='0 0 173 61' sx={{ height: '100%', width: '100%' }} />
                </IconButton>
                <span style={{ flexGrow: 1 }} />
                <Stack direction="row" spacing={2}>
                    {getIsLoggedIn() &&
                        <Button href='/dashboard' variant='contained'>Dashboard</Button>}
                    {getIsLoggedIn() ?
                        <Button variant='text' color='secondary' onClick={handleLogout}>Logout</Button>
                        : <Button variant='text' color='secondary' onClick={handleLogin}>Login</Button>}
                </Stack>
            </Toolbar>
        </AppBar>
    );
}

export default navbar;
