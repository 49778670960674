import React, { useState } from 'react';

import {
    Button,
    Checkbox, Container, FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel, Grid, InputAdornment, Stack,
    TextField,
    Typography
} from '@mui/material';

import { getOrder, submitOrder, updateOrder, uploadFile } from '../../../api/Midl';


const InvoiceDetails = ({ order, setOrder, setActiveStep, ...props }) => {
    const [formData, setFormData] = useState({ ...order });
    const [formErrors, setFormErrors] = useState({});

    const updateFormData = (key, value) => {
        setFormData({ ...formData, [key]: value });
    }

    const updatePaymentMethods = (event) => {
        var newPaymentMethods = [];
        if (event.target.checked) {
            newPaymentMethods = [...new Set([...formData.payment_method_types, event.target.name])];
        } else {
            newPaymentMethods = [...formData.payment_method_types];
            newPaymentMethods.splice(newPaymentMethods.indexOf(event.target.name), 1);
        }
        updateFormData('payment_method_types', newPaymentMethods);
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();
        const orderData = {
            name: formData.name,
            amount: formData.amount,
            payment_method_types: formData.payment_method_types
        }
        updateOrder(order.id, orderData)
            .then((order) => {
                getOrder(order.id)
                    .then((orderData) => {
                        setOrder(orderData);
                        setActiveStep(1);
                    });
            });
    }

    const handleFormChange = (event) => {
        updateFormData(event.target.name, event.target.value);
    }

    const calculateCCAmount = (amount) => {
        // 2.9% + 30¢
        const stripeFee = amount * ((2.9) / 100) + 0.3;
        const midlFee = amount * ((1.4) / 100);
        return amount - stripeFee - midlFee;
    }

    const calculateAchAmount = (amount) => {
        // 0.8% capped at $5.00 for standard settlement
        const stripeFee = Math.min(amount * ((0.8) / 100), 5);
        const midlFee = amount * ((1.4) / 100);
        return amount - stripeFee - midlFee;
    }

    const formatCurrency = (amount) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        return formatter.format(amount);
    }

    React.useEffect(() => {
        const updatedErrors = {};

        // Validate payment methods
        if (!formData.payment_method_types.length) {
            updatedErrors.payment_method_types = "At least one payment method is required";
        }

        setFormErrors(updatedErrors);
    }, [formData]);

    return (
        <Container maxWidth='md'>
            <form onSubmit={handleSubmitForm}>
                <TextField
                    name='name'
                    label='Order Name'
                    variant='standard'
                    margin='normal'
                    fullWidth
                    value={formData.name}
                    onChange={handleFormChange}
                />
                <FormHelperText sx={{ mb: 1 }}>The order name will appear on the invoice and will be sent to the customer.</FormHelperText>
                <TextField
                    name='amount'
                    label='Amount'
                    variant='standard'
                    margin='normal'
                    InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                    type='number'
                    fullWidth
                    value={formData.amount}
                    onChange={handleFormChange}
                />
                <FormHelperText sx={{ mb: 1 }}>The amount the customer will be charged.</FormHelperText>
                <FormControl sx={{ mt: 1 }} error={formErrors.payment_method_types}>
                    <FormLabel>Select Payment Methods</FormLabel>
                    <FormHelperText sx={{ ml: 0 }}>Select how your customer can pay. Payment methods have different different processing fees.</FormHelperText>
                    <FormGroup>
                        <Grid container>
                            <Grid item xs={6}>
                                <Stack>
                                    <FormControlLabel control={<Checkbox checked={formData.payment_method_types.includes('us_bank_account')} onChange={updatePaymentMethods} name="us_bank_account" />} label={
                                        <Typography>ACH</Typography>
                                    } />
                                    <FormHelperText>You receive {formatCurrency(calculateAchAmount(formData.amount))}</FormHelperText>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack>
                                    <FormControlLabel control={<Checkbox checked={formData.payment_method_types.includes('card')} onChange={updatePaymentMethods} name="card" />} label={
                                        <Typography>Credit Card</Typography>
                                    } />
                                    <FormHelperText>You receive {formatCurrency(calculateCCAmount(formData.amount))}</FormHelperText>
                                </Stack>
                            </Grid>
                        </Grid>
                    </FormGroup>
                    {formErrors.payment_method_types && <FormHelperText sx={{ ml: 0 }}>{formErrors.payment_method_types}</FormHelperText>}
                </FormControl>
                <Button fullWidth variant='contained' type="submit" sx={{ my: 4 }}>Continue</Button>
            </form>
        </Container>
    );
}
export default InvoiceDetails;