import React from 'react';

import Paper from '@mui/material/Paper';


const NotFound = (props) => {
    return (
        <Paper sx={{ margin: '1em auto', padding: '1em', maxWidth: '600px', textAlign: 'center' }}>
            <h1>404</h1>
            <h2>Page not found</h2>
            <h2>Please check your URL and try again</h2>
        </Paper>
    );
}
export default NotFound;