import React from 'react';
import { useNavigate } from 'react-router-dom';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Alert,
    Button, Container, FormControl,
    IconButton, InputAdornment,
    InputLabel, OutlinedInput, TextField, Typography
} from '@mui/material';

import { getIsLoggedIn, loggin } from '../../api/Auth';


const Login = (props) => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [failedLogin, setFailedLogin] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    React.useEffect(() => {
        if (getIsLoggedIn()) {
            return navigate('/dashboard')
        }
    }, []);

    const handleSubmitForm = (event) => {
        event.preventDefault();
        const email = event.target.email.value;
        const password = event.target.password.value;
        setLoading(true);
        setFailedLogin(false);
        loggin(email, password)
            .then((success) => {
                if (success) {
                    return navigate('/dashboard');
                } else {
                    setFailedLogin(true);
                }
                setLoading(false);
            });
    }

    return (
        <Container maxWidth='xs' sx={{ margin: '3em auto', textAlign: 'center' }}>
            <form onSubmit={handleSubmitForm}>
                <Typography variant='h1' gutterBottom>Login</Typography>
                {failedLogin && <Alert severity='error'>No active account found with the given credentials</Alert>}
                <TextField
                    fullWidth
                    error={failedLogin}
                    type='email'
                    label='Email'
                    name='email'
                    variant='outlined'
                    margin='normal'
                />
                <FormControl fullWidth variant='outlined' margin='normal'>
                    <InputLabel>Password</InputLabel>
                    <OutlinedInput
                        error={failedLogin}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    edge='end'
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label='Password'
                        name='password'
                    />
                </FormControl>
                <LoadingButton loading={loading} fullWidth variant='contained' type='submit' sx={{ marginTop: '1em' }}>Login</LoadingButton>
            </form>
            <Button fullWidth sx={{ marginTop: '1em' }} onClick={() => navigate('/forgot')}>Forgot password</Button>
        </Container>
    )
}

export default Login;