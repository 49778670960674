import React from 'react';

import ImageIcon from '@mui/icons-material/Image';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import FileUpload from './FileUpload';


const AddFilesList = ({ files, setSelectedFile, selectedFile, secondaryAction, addFiles, ...props }) => {

    return (
        <div style={{ height: '500px', display: 'flex', flexDirection: 'column', ...props.sx }}>
            <List sx={{ width: '100%', overflow: 'auto', m: 1 }}>
                {files.map((file, index) => (
                    <ListItem
                        key={file.id}
                        secondaryAction={secondaryAction ? secondaryAction(file) : null}
                        disablePadding
                    >
                        <ListItemButton
                            onClick={() => setSelectedFile(index)}
                            selected={index === selectedFile}
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <ImageIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={file.name} sx={{ overflow: 'hidden' }} />
                        </ListItemButton>
                    </ListItem>
                ))}

            </List>
            <FileUpload addFiles={addFiles} sx={{ minHeight: '100px',  flex: 1 }} />
        </div>
    );
}

export default AddFilesList;
