import React from 'react';


import { Alert, AlertTitle, Button, Container, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import FileDisplay from '../common/FileDisplay';



const statusLable = {
    'Open':
        <Alert variant="filled" severity="info" sx={{ width: '100%', textAlign: 'left', marginBottom: '1em' }}>
            <AlertTitle>Awaiting Payment</AlertTitle>
            Invoice has been sent to customer.<br />
            Once paid they will be able to download the files.
        </Alert>,
    'Accepted':
        <Alert variant="filled" severity="success" sx={{ width: '100%', textAlign: 'left', marginBottom: '1em' }}>
            <AlertTitle>Paid</AlertTitle>
            Customer has paid this invoice.
        </Alert>
};

const OrderView = ({ order, ...props }) => {
    const navigate = useNavigate();

    return (
        <Container maxWidth='sm' sx={{ textAlign: "center" }}>
            {statusLable[order.status]}
            <Typography variant='h1' gutterBottom>{order.name}</Typography>
            <Typography>Sent to</Typography>
            <Typography variant='h2' gutterBottom>{order.customer.email}</Typography>
            <Typography variant='h2' gutterBottom>{order.customer.name}</Typography>
            <Typography variant='h2' sx={{ textAlign: "left" }}>Files:</Typography>
            <FileDisplay files={order.files}/>
            <Button
                variant='contained'
                sx={{ display: 'block', margin: '1em auto' }}
                onClick={() => {
                    navigate("/checkout/" + order.id);
                }}
            >
                View Payment Page
            </Button>
            <Button
                sx={{ display: 'block', margin: '1em auto' }}
                onClick={() => {
                    navigate("/dashboard");
                }}
            >
                Back to Dashboard
            </Button>
        </Container>
    );
}
export default OrderView;