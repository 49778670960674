import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Alert,
    AlertTitle,
    Button,
    Container,
    Typography
} from '@mui/material';

import { getAccount } from '../../api/Midl';


const Onboarding = (props) => {
    const navigate = useNavigate();
    const [account, setAccount] = useState({});

    React.useEffect(() => {
        getAccount()
            .then((resp) => {
                setAccount(resp);
                if (resp.stripe_onboarding_complete) {
                    navigate('/dashboard');
                }
            });
    }, []);

    const accountStatusAlert = (status) => {
        if (!status) {
            return (<></>);
        }
        if (account.stripe_disabled_reason === 'requirements.past_due') {
            return (
                <Alert severity="error" sx={{ textAlign: "left" }}>
                    <AlertTitle>Missing information</AlertTitle>
                    Please click on button below to complete Stripe registraion.
                </Alert>
            );
        } else if (['requirements.pending_verification', 'under_review'].includes(account.stripe_disabled_reason)) {
            return (
                <Alert severity="info" sx={{ textAlign: "left" }}>
                    <AlertTitle>Pending verification</AlertTitle>
                    Your information is pending verification by Stripe. Please come back later.
                </Alert>
            );
        } else {
            return (
                <Alert severity="error" sx={{ textAlign: "left" }}>
                    <AlertTitle>Issue with Stripe account</AlertTitle>
                    Please contact support@midlpay.com
                </Alert>
            );
        }
    }

    return (
        <Container maxWidth='sm' sx={{ textAlign: "center" }}>
            <Typography variant='h1'>Welcome</Typography>
            <Typography gutterBottom variant='h1'>{account.full_name}</Typography>
            <Typography sx={{ marginBottom: '1em' }}>MIDL is the Financial Solution for Creative Professionals in which the files and payments are together and not separate.</Typography>
            <Typography sx={{ marginBottom: '1em' }}>Our mission is to give creatives more time to do what they do best, while getting paid on time.</Typography>
            {accountStatusAlert(account.stripe_disabled_reason)}
            <Button
                href="/stripe_account"
                variant='contained'
                size='large'
                fullWidth
                color='stripe'
                sx={{
                    marginTop: '2em',
                    color: 'white',
                    "&:hover": {
                        color: "white"
                    }
                }}
            >Connect with Stripe</Button>
            <Typography color='secondary' sx={{ marginTop: '1em' }}>We partner with Stripe for secure payment processing.</Typography>
            <Typography color='secondary'>This will only take a few minutes.</Typography>
        </Container>
    );
}

export default Onboarding;