import React, { useState } from 'react';

import {
    Button,
    Container,
    Typography
} from '@mui/material';

import { getOrder, updateOrder } from '../../../api/Midl';
import AddFilesComponent from './AddFilesComponent';



const AddFiles = ({ order, setOrder, setActiveStep, ...props }) => {
    const [formData, setFormData] = useState({ ...order });

    const updateFormData = (key, value) => {
        setFormData({ ...formData, [key]: value });
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();
        const orderData = {
            files: formData.files.map(file => file.id)
        }
        updateOrder(order.id, orderData)
            .then((order) => {
                getOrder(order.id)
                    .then((orderData) => {
                        setOrder(orderData);
                        setActiveStep(2);
                    });
            });
    }

    return (
        <Container>
            <form onSubmit={handleSubmitForm}>
                <Typography variant='h2' sx={{ textAlign: 'center', mt: 4 }}>{order.name}</Typography>
                <AddFilesComponent files={formData.files} updateOrder={updateFormData} />
                <Button fullWidth variant='contained' type="submit" sx={{ mt: 4 }}>Continue</Button>
                <Button fullWidth sx={{ mt: 1 }} onClick={() => { setActiveStep(0) }}>Back</Button>
            </form>
        </Container>
    );
}
export default AddFiles;