import React from 'react';
import { NotificationManager } from 'react-notifications';

import {
    Button,
    Container
} from '@mui/material';


import { getOrder, submitOrder, updateOrder } from '../../../api/Midl';
import CustomerCreateOptionDialog from '../CustomerCreateOptionDialog';



const Send = ({ order, setActiveStep, setOrder, ...props }) => {

    const handleSubmitOrder = (event) => {
        event.preventDefault();
        submitOrder(order.id)
            .then((resp) => {
                NotificationManager.success(resp['detail'], 'Success!', 2000);
                window.location.reload();
            });
    }

    const updateCustomer = (value) => {
        if (!value) {
            return;
        }

        const orderData = {
            customer: value.id,
        }
        updateOrder(order.id, orderData)
            .then((order) => {
                NotificationManager.success('Updated order details', 'Success!', 2000);
                getOrder(order.id)
                    .then((orderData) => {
                        setOrder(orderData);
                    });
            });
    }

    return (
        <Container>
            <CustomerCreateOptionDialog
                value={order.customer}
                onChange={updateCustomer}
            />
            <Button fullWidth variant='contained' onClick={handleSubmitOrder} disabled={!order.customer} sx={{ mt: 4 }}>Submit to customer</Button>
            <Button fullWidth sx={{ mt: 1 }} onClick={() => { setActiveStep(1) }}>Back</Button>
        </Container>
    );
}
export default Send;