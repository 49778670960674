import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAccount } from '../../api/Midl';



const OnboardedRoute = (props) => {
    const navigate = useNavigate();
    const [isOnboarded, setIsOnboarded] = useState(false);

    React.useEffect(() => {
        getAccount()
          .then((resp) => {
            setIsOnboarded(resp.stripe_onboarding_complete);
            if (!!!resp.stripe_onboarding_complete) {
              navigate('/onboarding');
            }
          });
      }, []);

    return (
        <>
            {
                isOnboarded ? props.children : null
            }
        </>
    );
}
export default OnboardedRoute;