import React from 'react';

import { Container, Typography } from '@mui/material';

const TermsOfService = (props) => {
    return (
        <Container maxWidth='xl' sx={{ margin: '3em auto' }}>
            <Typography variant='h1' gutterBottom>Terms of Service</Typography>
            <Typography>MIDL is an exclusive payment platform empowering
                creators to earn from doing what they love. MIDL strives to pioneer a new medium for creative
                professionals to get paid.</Typography>
            <Typography><strong>Welcome to MIDL!</strong></Typography>
            <Typography>By using MIDL you agree to these terms and conditions
                (as amended, modified or supplemented from time to time, the “Terms”).</Typography>
            <Typography>These are MIDL’s Terms, and they apply to all Users
                of
                the MIDL platform. “We,” “our” or “us” refers to MIDL Pay LLC and our affiliates. “MIDL” refers to this
                platform and the services offered by us. By using MIDL, you agree to these terms as well as any other
                terms that we may post on our website, as updated from time to time. Please read them carefully and let
                us know if you have any questions. For information about our data practices, please see our</Typography>
            <Typography>. We can collect and use your information in
                accordance with those policies.</Typography>
            <Typography><strong>Definitions</strong></Typography>
            <Typography><strong>Content</strong></Typography>
            <Typography>means any material uploaded to MIDL by any User
                including any photos, videos, audio (for example music and other sounds), livestream material, data,
                text (such as comments and hashtags), metadata, images, interactive features, emojis, GIFs, memes, and
                any other material whatsoever.</Typography>
            <Typography><strong>Creator</strong></Typography>
            <Typography>means a User who has set up a MIDL Creator account to
                post Content on MIDL to be viewed by other Users.</Typography>
            <Typography><strong>Customer</strong></Typography>
            <Typography>means a User who purchases content from Creators.
            </Typography>
            <Typography><strong>User</strong></Typography>
            <Typography>means any user of MIDL, whether a Creator or a
                Customer or both (also referred to as “you” or “your”).</Typography>
            <Typography><strong>Special Terms for Customers</strong>
            </Typography>
            <Typography><strong>Creating a Customer Account</strong>
            </Typography>
            <Typography>To become a customer, simply create an account, add
                your preferred payment method, and join a membership program on MIDL to start supporting your Creator!
            </Typography>
            <Typography><strong>Locked Content</strong></Typography>
            <Typography>Creators may generate Content that is behind a
                paywall
                (a “Locked Content Message”). Creators may send Locked Content Messages to Customers who agreed to be
                billed by the Creator. To access the locked Content in the Locked Content Message, Customers must pay a
                pre-populated price included in the Locked Content Message.</Typography>
            <Typography>All Content included in a Locked Content Message must
                comply with these Terms and our Content Guidelines.</Typography>
            <Typography>The ability to send Locked Content Messages is only
                available to Creators who are over the age of 18.</Typography>
            <Typography><strong>Taxes and Other Fees</strong></Typography>

            <Typography>If you are located in a jurisdiction in which MIDL is
                required to charge and collect tax (e.g. VAT or, sales tax), then this tax is added to your total
                charge. Except in limited circumstances, this tax will be shown when you set up the initial subscription
                or purchase a prepaid direct message to a Creator. MIDL remits all tax collected to the applicable
                taxing authority. As tax is largely dependent on your location, you are responsible for keeping your
                address complete and up to date in your MIDL account. Depending on your location, some banks may charge
                you a foreign transaction fee for payments processed through the Platform. MIDL does not control this
                charge, but it is typically around 3%. Please contact your bank for more information.</Typography>
            <Typography><strong>Indemnity from Customers</strong>
            </Typography>
            <Typography>If we are sued because of your use of or conduct on
                MIDL, you have to help pay for it. Customers will indemnify MIDL and its parents, affiliates,
                third-party service providers, subsidiaries, and related companies, officers, directors, agents, and
                employees from and against any and all costs, liabilities, demands, claims, suits, actions, damages,
                losses, judgments and expenses, including without limitation, attorneys’ fees (collectively, “Damages”)
                arising out of or related to a Customer’s use of MIDL. MIDL reserves the right to exclusive control over
                the defense of a claim covered by this clause. If we exercise our right to control the defense of a
                claim asserted against us, then you will cooperate with us and help us in our defense.</Typography>
            <Typography><strong>Limitation of Liability Related to
                Customers</strong></Typography>
            <Typography>TO THE EXTENT PERMITTED BY LAW, WE ARE NOT LIABLE TO
                YOU FOR ANY INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF THESE TERMS, OR YOUR USE OR
                ATTEMPTED USE OF MIDL. TO THE EXTENT PERMITTED BY LAW, OUR LIABILITY FOR DAMAGES IS LIMITED TO THE
                AMOUNT OF MONEY WE HAVE EARNED FROM YOU THROUGH YOUR USE OF MIDL IN THE TWELVE MONTHS LEADING UP TO THE
                DATE THE CLAIM AROSE. WE ARE SPECIFICALLY NOT LIABLE FOR LOSS ASSOCIATED WITH UNFULFILLED BENEFITS AND
                FROM LOSSES CAUSED BY CONFLICTING CONTRACTUAL AGREEMENTS. FOR THIS CLAUSE “WE” AND “OUR” IS DEFINED TO
                INCLUDE OUR SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND THIRD-PARTY SERVICE
                PROVIDERS.</Typography>
            <Typography><strong>Special Terms for Creators</strong>
            </Typography>
            <Typography><strong>All about being a
                Creator</strong></Typography>
            <Typography>A Creator is someone who creates a membership page on
                MIDL to engage with their customers through subscription memberships and/or prepaid direct messages. As
                a Creator on MIDL, you can do many things to connect with your customers including A) using Creator
                tools that MIDL provides, B) showcasing your creations, C) providing merchandise to customers through
                MIDL’s Merch service, D) getting tips from customers through their purchase of prepaid direct messages
                to you, and E) receiving recurring revenue from customer subscribers to your page. There are a lot of
                details below involving payments, fees, taxes and restrictions that you should read in full if you are a
                Creator.</Typography>
            <Typography>To become a Creator, simply apply through the MIDL
                website or app. Eligibility requirements and limitations apply as determined in MIDL’s sole discretion.
                If we accept your application then you’ll be notified that you are a Creator on MIDL.</Typography>
            <Typography><strong>Subscription Memberships and
                Customers</strong></Typography>
            <Typography>Subscription memberships are for your most passionate
                Customers. On MIDL, you can provide your Customers something exciting that gives them unique benefits,
                like exclusive engagement, early access to content, merchandise, and engaging experiences. In turn, you
                will receive loyal support from your Customers and recurring revenue from the subscription memberships
                and/or prepaid direct messages.</Typography>
            <Typography>If your Customers include people under the age of 18,
                then please remind them that they need permission to join your membership. Also remind those under the
                age of 13 that they cannot use MIDL. We are not required to allow any particular person or group of
                persons to be your customer.</Typography>
            <Typography><strong>Payments</strong></Typography>

            <Typography>On MIDL, Creators receive 80% of all income earned on
                MIDL, which is paid to Creators approximately 30 days after the end of the applicable calendar month.
                MIDL keeps the other 20% off every transaction on the platform (the “MIDL Fee”). The MIDL Fee currently
                includes payment processing fees but MIDL reserves the right to charge payment processing fees in the
                future. The MIDL Fee does not include any taxes, including, but not limited to income taxes on Creator
                earnings, which is the sole responsibility of each Creator. For more information, see “Tax” below.</Typography>

            <Typography>As a Creator, you make your membership available on
                MIDL, and we provide membership to your Customers on a subscription basis as well as your Customers’
                ability to pay for direct messages to you. We also handle payments issues such as fraud, chargebacks and
                resolution of payments disputes. We try to provide timely access to your funds on about a monthly basis,
                but you may occasionally experience delays in receiving or accessing your funds. We may also block or
                hold payments for violations of our policies or for compliance reasons, including collecting tax
                reporting information. When payments are delayed or blocked, we try to communicate the reason to you
                promptly. If you have questions about a payment that has been blocked, please contact us at
                hello@MIDL.io. In order to protect Creators, we may block Customers’ payments if we believe them to be
                fraudulent. Sometimes activities like refunds can put your account balance into the negative. If your
                balance becomes negative then we reserve the right to recover those funds from future payments.</Typography>
            <Typography>Depending on your Customers’ locations, some banks
                may
                charge your Customers a foreign transaction fee for their membership subscription or direct messages.
                MIDL does not control this charge, but it is typically around 3.0%.</Typography>
            <Typography><strong>Tax</strong></Typography>

            <Typography>We collect tax identification information, W-9s (and
                other similar documentation), and report this to tax authorities as legally required. You are required
                to complete any tax documentation we request and report any income as required by applicable law. All
                international, federal, state or other tax liabilities (including income taxes) arising your use of MIDL
                will be your sole responsibility unless otherwise explicitly noted by us. If you are a Creator, you
                agree that we may deduct and withhold from any earned fees any sums, including all tax obligations,
                required to be withheld and/or paid by us to any governmental authority pursuant to any law, statute,
                ordinance, rule, judgment, or decree now or hereafter in effect. MIDL has the right to withhold taxes it
                deems appropriate in its sole discretion or if required, including, but not limited to making double
                withholdings for the USA and Creator’s country of residence.</Typography>
            <Typography><strong>Ownership of Your Account</strong>
            </Typography>

            <Typography>MIDL shall at all times, including before, during and
                after your use of the Platform, constitute the sole owner of your MIDL “Creator” account. Creator
                understands and agrees that all Content and other content added to Creator’s MIDL “Creator” account is
                subject to our prior review and approval and cannot be deleted or materially modified by Creator without
                our prior written consent which may be withheld or granted at our sole discretion.</Typography>
            <Typography><strong>Ownership and License of Your
                Content</strong>
            </Typography>

            <Typography>You retain any ownership rights you have in your
                Content, but you grant MIDL the following license to use your Content:</Typography>
            <Typography>You grant MIDL a worldwide, royalty-free, perpetual,
                irrevocable, transferable, and sublicensable license to use, copy, modify, adapt, prepare derivative
                works of, distribute, store, perform, and display your Content and any name, username, voice, or
                likeness provided in connection with your Content in all media formats and channels now known or later
                developed anywhere in the world.</Typography>
            <Typography>This license shall be exclusive to MIDL while Creator
                has an active MIDL account.</Typography>
            <Typography>MIDL takes no responsibility for and does not
                expressly or implicitly endorse, support, or guarantee the completeness, truthfulness, accuracy, or
                reliability of any Content. Although we have no obligation to screen, edit, or monitor your Content, we
                may, at our sole discretion, delete or remove your Content at any time and for any reason, including for
                violating these Terms, our</Typography>
            <Typography><a href="https://www.midlpay.com/privacy-policy">Privacy
                Policy</a></Typography>
            <Typography>, or our</Typography>
            <Typography><a href="https://www.midlpay.com/content-guidelines">Content
                Guidelines</a></Typography>
            <Typography>, or if you otherwise create or are likely to create
                liability for us.</Typography>
            <Typography><strong>Unauthorized Use of Your Content</strong>
            </Typography>

            <Typography>You acknowledge that once your Content is posted on
                MIDL, we cannot control and will not be responsible to you for the use which other Users or third
                parties make of such Content. You can delete your account at any time, but you acknowledge that deleting
                your account will not of itself prevent the circulation of any of your Content which may have been
                recorded by other Users in breach of the Terms of Service or by third parties prior to the deletion of
                your account.</Typography>

            <Typography><strong>Creator’s Third-party Accounts</strong>
            </Typography>

            <Typography>You may grant MIDL access to your third-party
                accounts, such as Google and Twitter for authentication relates purposes or for some MIDL features to
                operate. Each time you connect your third-party account, that third-party account will present a page
                that describes the information that MIDL can access. At any time, you can revoke MIDL’s access to those
                accounts using the respective third party’s security settings page.</Typography>
            <Typography><strong>Creator’s Feedback</strong></Typography>

            <Typography>Any ideas, suggestions, and feedback about MIDL or
                our
                services that you provide to us are entirely voluntary, and you agree that MIDL may use such ideas,
                suggestions, and feedback without compensation or obligation to you.</Typography>
            <Typography><strong>MIDL&#x27;s creations</strong></Typography>

            <Typography>Our creations are protected by copyright, trademark
                and trade secret laws. Some examples of our creations are the text on the site, our logo, and our
                codebase. We grant you a fully paid up and royalty-free, non-exclusive, revocable license to use,
                display, and distribute our logo and other copyrights or trademarks to promote your MIDL page. You may
                not otherwise use, reproduce, distribute, perform, publicly display or prepare derivative works of our
                creations unless we give you permission in writing. If you have any questions about how to use our logo
                or other copyrights or trademarks, please contact us at hello@midlpay.com.</Typography>
            <Typography><strong>Creator’s Personal Responsibility</strong>
            </Typography>

            <Typography>Only individuals are allowed to be Creators. Every
                Creator is bound personally by these Terms. When you create an account you must provide us with accurate
                information, in good faith, and you agree to keep your information updated if it changes.</Typography>

            <Typography>You (and/or your parent or guardian if you are under
                18 years old or not yet an adult) are responsible for anything that occurs when anyone is signed into
                your account, as well as the security of the account. If you have an agent, agency, management company
                or other third party which assists you with the operation of your Creator account (or operates it on
                your behalf), this does not affect your personal legal responsibility. MIDL’s relationship is with you,
                and not with any third party, and you will be legally responsible for ensuring that all Content posted
                and all use of your account complies with the Terms. Please contact us immediately at</Typography>
            <Typography><a href="mailto:hello@midlpay.com.">hello@midlpay.com.</a></Typography>
            <Typography>if you believe your account has been compromised.
            </Typography>
            <Typography><strong>Compliance with Law</strong></Typography>

            <Typography>We have the right to monitor Creators and Content and
                its compliance with these Terms and applicable law, including but not limited to compliance with the FTC
                Guides Concerning Use of Endorsements and Testimonials in Advertising (the “FTC Guides”) (found at</Typography>
            <Typography><a
                href="https://www.ecfr.gov/current/title-16/chapter-I/subchapter-B/part-255">https://www.ecfr.gov/current/title-16/chapter-I/subchapter-B/part-255</a>
            </Typography>
            <Typography>and</Typography>
            <Typography><a
                href="http://www.ftc.gov/os/2009/10/091005revisedendorsementguides.pdf">http://www.ftc.gov/os/2009/10/091005revisedendorsementguides.pdf</a>
            </Typography>
            <Typography>) and the FTC’s Disclosures 101 for Social Media
                Creators (found at</Typography>
            <Typography><a
                href="https://www.ftc.gov/system/files/documents/plain-language/1001a-Creator-guide-508_1.pdf">https://www.ftc.gov/system/files/documents/plain-language/1001a-Creator-guide-508_1.pdf</a>
            </Typography>
            <Typography>). We have the right to address noncompliant uses
                and/or publications of the Content (as determined by us in our sole discretion) by taking any of the
                following actions alone or in combination: (a) requiring you to fix the Content; (b) fixing the Content
                on our own or through an agent; (c) withholding payment (or any portion thereof); and/or (d) terminating
                your account. Without limiting the generality of the foregoing, at our request, Creator agrees to
                immediately remove or modify (as instructed by MIDL) any Content or portion thereof that MIDL determines
                in its sole discretion is not in compliance with these Terms or the FTC Guides.</Typography>
            <Typography><strong>Sanctions Compliance</strong></Typography>

            <Typography>Export laws and regulations of the United States and
                other applicable jurisdictions may prohibit us from conducting business with certain Creators.
                Additionally, the United States Department of Treasury Office of Foreign Assets Control (“OFAC”) has
                implemented many economic or financial sanctions programs that could prevent us from sending funds or
                information to Creators in certain countries. It is your responsibility as a Creator to ensure that
                there are no export laws, export regulations, or OFAC sanctions programs that affect your use of MIDL.
                The U.S. Department of the Treasury maintains a list of active OFAC sanctions programs.</Typography>

            <Typography>You represent that you are not named on any U.S.
                government list of persons or entities prohibited from receiving exports, and you shall not permit any
                Users to access or use MIDL in violation of any U.S. export embargo, prohibition, or restriction. Your
                use of MIDL constitutes your acknowledgement of OFAC sanctions programs and agreement that you, as a
                Creator, will either forego using MIDL or obtain (and provide us with proof of) an exemption using the
                appropriate channels if any OFAC sanctions programs impact your use of MIDL.</Typography>
            <Typography>You represent and warrant that your use of MIDL will
                not violate any regulations administered and enforced by any OFAC sanctions program.</Typography>
            <Typography><strong>Representations and Warranties of
                Creator</strong></Typography>

            <Typography>Creator represents and warrants that: (i) it is of
                legal and consenting age to enter into these Terms or has the permission of a lawful parent or guardian
                to enter into these Terms; (ii) it will perform its obligations hereunder in accordance with all
                applicable laws, rules, and regulations; (iii) other than elements provided by MIDL, the Content shall
                be Creator’s own original work, created solely by Creator, and will not defame any third party or
                violate or infringe upon any third party rights, including, without limitation, intellectual property
                rights and rights of publicity and privacy, and any obligations of confidentiality; (iv) there will be
                no restraint or limitation upon MIDL’s right to use the Content and the Content therein (including
                Creator’s Likeness) in accordance with these Terms; (v) Creator will not use any third party Content or
                proprietary materials (including logos, names, images, etc.) in the Content without first obtaining all
                necessary rights and licenses for use including the express written consent of MIDL; (vi) Creator will
                not disparage or denigrate the Platform, MIDL, its products or services, or its association with MIDL;
                and (vii) other than elements provided by MIDL, the Content shall comply with any Content, brand
                guidelines, or usage restrictions provided by MIDL or on this website, including our</Typography>
            <Typography><a href="https://www.midlpay.com/content-guidelines">Content
                Guidelines</a></Typography>

            <Typography><strong>Indemnity from Creators</strong></Typography>

            <Typography>Creator will defend, indemnify and hold MIDL and its
                parent, affiliates, subsidiaries, and related companies, officers, directors, agents, and employees from
                and against any and all costs, liabilities, demands, claims, suits, actions, damages, losses, judgments
                and expenses, including without limitation, attorneys’ fees (collectively, “Damages”) arising out of or
                related to: (a) any breach or alleged breach of Creator’s obligations, representations, or
                responsibilities under these Terms; (b) the Content; (c) Creator’s development, production,
                distribution, operation, or exploitation of the Content, including but not limited to, Creator’s failure
                to comply with applicable disclosure requirements under applicable laws, such as the FTC Endorsement
                Guidelines, (d) Creator’s negligence, gross negligence, willful misconduct, or fraud, (e) any
                unauthorized use, disclosure, or infringement of any person’s or entity’s intellectual property or
                proprietary rights, and (f) Creator’s Content or MIDL usage. MIDL may, at its election, assume the
                defense, settlement or other resolution of such claim with counsel of its own choosing, at Creator’s
                cost and expense.</Typography>

            <Typography><strong>Release and Limitation of Liability Related
                to
                Creators</strong></Typography>

            <Typography>CREATOR, DOES HEREBY AGREE TO RELEASE MIDL AND EACH
                OF
                ITS PARENTS, AFFILIATES, SUBSIDIARIES, PARTNERS, MEMBERS, SHAREHOLDERS, OFFICERS, DIRECTORS, EMPLOYEES,
                AGENTS, AND SUBCONTRACTORS (HEREINAFTER INDIVIDUALLY AND COLLECTIVELY REFERRED TO AS THE “RELEASEES”)
                WITH RESPECT TO ANY AND ALL INJURY, DISABILITY, DEATH, OR DAMAGE (INCLUDING ATTORNEYS’ FEES AND
                EXPENSES) TO PERSON, PROPERTY OR REPUTATION THAT CREATOR MAY HAVE ARISING OUT OF OR IN CONNECTION WITH
                CREATOR’S PARTICIPATION IN ANY MIDL EVENTS, WHETHER ARISING FROM THE NEGLIGENCE OF ONE OR MORE OF THE
                RELEASEES, THIRD PARTIES, OR OTHERWISE, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
                NOTWITHSTANDING ANYTHING ELSE HEREIN, MIDL SHALL NOT BE LIABLE TO CREATOR OR ANY PARTY ASSOCIATED WITH
                OR ACTING ON BEHALF OF CREATOR PURSUANT TO OR IN CONNECTION WITH THESE TERMS, OR ON THE BASIS OF TORT OR
                ANY OTHER LEGAL OR EQUITABLE THEORY, FOR (I) ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
                OR PUNITIVE DAMAGES, OR LOST PROFITS, EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES AND (II) ANY AMOUNT IN EXCESS OF THE FEES PAID TO CREATOR TO MIDL IN THE TWELVE MONTHS LEADING
                UP TO THE DATE THE CLAIM AROSE.</Typography>

            <Typography><strong>General Terms for All Users</strong>
            </Typography>
            <Typography><strong>Who Can Use
                MIDL</strong><strong>
                </strong><strong>‍</strong></Typography>
            <Typography>We want MIDL to be as open and inclusive as possible,
                but we also want it to be safe, secure, and in accordance with the law. So, we need you to commit to a
                few restrictions in order to be part of the MIDL community.</Typography>
            <Typography>- You must be at least 18 years old, or 13 years old
                with the permission of a parent or guardian to use MIDL.</Typography>
            <Typography>- You must not be prohibited from receiving any
                aspect
                of our services under applicable laws or engaging in payments related to our services if you are on an
                applicable denied party listing.</Typography>
            <Typography>- We must not have previously disabled your account
                for violation of law or any of our policies.You must not be a convicted sex offender.</Typography>
            <Typography>As a Creator, you must not be a politician or public
                official.</Typography>
            <Typography><strong>How You Can&#x27;t Use MIDL</strong>
            </Typography>

            <Typography>Providing a safe and open service for a broad
                community requires that we all do our part.</Typography>
            <Typography>- You can&#x27;t violate (or help or encourage others
                to violate) these Terms or our policies, including our</Typography>
            <Typography><a href="https://www.midlpay.com/content-guidelines">Content
                Guidelines</a></Typography>
            <Typography>. If you post branded content, you must comply with
                any provided branded content policies.You can&#x27;t do anything unlawful, misleading, or fraudulent or
                for an illegal or unauthorized purpose.</Typography>
            <Typography>- You can&#x27;t post someone else’s private or
                confidential information without permission or do anything that violates someone else&#x27;s rights,
                including intellectual property rights (e.g., copyright infringement, trademark infringement,
                counterfeit, or pirated goods).</Typography>
            <Typography>- You may use someone else&#x27;s works under
                exceptions or limitations to copyright and related rights under applicable law. You represent you own or
                have obtained all necessary rights to the Content you post or share.</Typography>
            <Typography>- You can&#x27;t impersonate others or provide
                inaccurate information. You don&#x27;t have to disclose your identity on MIDL, but you must provide us
                with accurate and up to date information (including registration information), which may include
                providing personal data. Also, you may not impersonate someone or something you aren&#x27;t, and you
                can&#x27;t create an account for someone else unless you have their express permission.</Typography>
            <Typography>- You can&#x27;t do anything to interfere with or
                impair the intended operation of MIDL. This includes misusing any reporting, dispute, or appeals
                channel, such as by making fraudulent or groundless reports or appeals.</Typography>
            <Typography>- You can’t sell, license, or purchase any account or
                data obtained from MIDL. This includes attempts to buy, sell, or transfer any aspect of your account
                (including your username); solicit, collect, or use login credentials or badges of other Users; or
                request or collect MIDL usernames, passwords, or misappropriate access tokens.</Typography>
            <Typography>- You can&#x27;t attempt to create accounts or access
                or collect information in unauthorized ways. This includes creating accounts or collecting information
                in an automated way without our express permission.</Typography>
            <Typography>- You can’t modify, translate, create derivative
                works
                of, or reverse engineer our products or their components.You can&#x27;t use a domain name or URL in your
                username without our prior written consent.</Typography>

            <Typography><strong>MIDL&#x27;s Role</strong></Typography>
            <Typography><strong></strong><strong>Customer
                Support</strong><strong> </strong><strong>‍</strong></Typography>
            <Typography>For questions concerning your account, payments, our
                services, or these Terms, as well as general customer support, please contact us at</Typography>
            <Typography><a href="mailto:hello@midlpay.com">hello@midlpay.com</a></Typography>


            <Typography><strong>Content Moderation</strong></Typography>
            <Typography>While we are not obligated to, we proactively look at
                some pages and posts on MIDL to make sure Users follow these Terms and our</Typography>
            <Typography><a href="https://www.midlpay.com/terms-of-service">Content
                Guidelines</a></Typography>
            <Typography>. We also investigate reports of potential
                violations.
                These investigations may take a while to resolve and may include looking at what is supported by funds
                received through MIDL. In most situations we will work with Users to resolve any potential violations
                and allow their continued use of MIDL.</Typography>
            <Typography><strong>Account deletion</strong></Typography>

            <Typography>We may terminate or suspend your account at any time
                and for any reason, at our sole discretion. We may also cancel any membership subscriptions or remove
                any Content (including comments) at any time and for any reason, at our sole discretion. You may not
                bring a claim against us for suspending or terminating another person’s account, and you agree you will
                not bring such a claim. If you try to bring such a claim, you are responsible for the damages caused,
                including attorneys’ fees and costs. These terms remain in effect even if you no longer have an account.
                Upon deletion of your account, MIDL may deal with your Content in any appropriate manner in accordance
                with our</Typography>
            <Typography><a href="https://www.midlpay.com/privacy-policy">Privacy
                Policy</a></Typography>
            <Typography>(including by deleting it) and you will no longer be
                entitled to access your Content. There is no technical functionality for you to be able to access your
                Content following termination of your MIDL account. We do not allow refunds, though we may grant
                exceptions at our sole discretion. We do not prorate fees upon early termination.</Typography>
            <Typography><strong>Updates</strong></Typography>

            <Typography>We are constantly testing out new features with the
                goal of making MIDL better. We may add or remove features, and often test features with a random subset
                of our community. If we believe a feature is significantly different from these terms, then we explain
                those differences in the test.</Typography>
            <Typography><strong>Privacy</strong></Typography>

            <Typography>With your permission, we may give other websites or
                services the ability to verify information about your MIDL account or perform actions on your behalf.
                This permission is asked for when you connect your MIDL account to these other websites or services. You
                can learn more in our</Typography>
            <Typography><a href="https://www.midlpay.com/privacy-policy">Privacy
                Policy</a></Typography>


            <Typography><strong>Sanctions Compliance</strong></Typography>

            <Typography>As a global company, MIDL is based in the United
                States with operations in other countries, we must comply with economic sanctions and trade
                restrictions, including those implemented by the Office of Foreign Assets Control (“OFAC”) of the U.S.
                Department of the Treasury. This means that MIDL cannot take part in transactions that involve
                designated people, places, or items that originate from those places, as determined by agencies like
                OFAC.</Typography>
            <Typography><strong>General Provisions</strong></Typography>
            <Typography><strong>Warranty
                disclaimer</strong></Typography>
            <Typography>MIDL IS PROVIDED “AS IS” AND WITHOUT WARRANTY OF ANY
                KIND. ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND ANY OTHER
                WARRANTY IS EXCLUDED TO THE GREATEST EXTENT PERMITTED BY LAW. THE DISCLAIMERS OF WARRANTY UNDER THIS
                CLAUSE ALSO APPLY TO OUR SUBSIDIARIES, AFFILIATES AND THIRD PARTY SERVICE PROVIDERS.</Typography>

            <Typography><strong>Force Majeure</strong></Typography>

            <Typography>We won’t be held liable for any delays or failure in
                performance of any part of MIDL from any cause beyond our control. This includes, but is not limited to,
                acts of god, changes to law or regulations, embargoes, war, terrorist acts, riots, fires, earthquakes,
                nuclear accidents, floods, strikes, power blackouts, volcanic action, unusually severe weather
                conditions, and acts of hackers or third-party internet service providers.</Typography>
            <Typography><strong>Dispute resolution</strong></Typography>

            <Typography>We encourage you to contact us if you have an issue.
                If a dispute does arise out of these terms or in relation to your use of MIDL, then the dispute will be
                resolved in the federal or state courts located in New York City, New York. Both parties consent to the
                exclusive jurisdiction and venue of the New York City courts for the purpose of resolving any such
                dispute. New York law, excluding its conflict of law provisions, governs these terms, all other MIDL
                policies, and any dispute that arises between you and MIDL.</Typography>
            <Typography><strong>Miscellaneous</strong></Typography>

            <Typography>These Terms are an agreement between you and MIDL.
                These terms and any referenced policies are the entire agreement between you and MIDL and supersede all
                prior agreements. You may not assign any of your rights under these Terms to anyone else. We may assign
                is agreement and any of our rights and obligations hereunder to any other individual or entity at our
                discretion. If any provision of these terms is held to be unenforceable, then that provision is modified
                to the extent necessary to enforce it. If a provision cannot be modified, it is severed from these
                terms, and all other provisions remain in force. If either party fails to enforce a right provided by
                these terms, it does not waive the ability to enforce any rights in the future. We may sometimes make
                changes to these terms and policies. If we make material changes that adversely affect your rights, then
                we will let you know before the changes come into effect. We reserve the right to make nonmaterial
                changes by simply updating these Terms from time to time at our sole discretion. Continuing to use MIDL
                after a change means you accept the new terms or policies to the extent permitted by law. If we don’t
                immediately take action on a violation of these Terms, then we’re not giving up any rights under these
                Terms and we may still take action at some point. Even if these Terms are terminated by you or us, the
                following sections will continue to apply: Indemnity for Customers, Indemnity for Creators,
                Representations and Warranties of Creator, Restrictions, Warranty Disclaimer, Limitation of Liability
                Related to Customers, Release and Limitation of Liability Related to Creators, Dispute Resolution, and
                Miscellaneous.</Typography>
        </Container>
    );
}
export default TermsOfService;
