import React, { useState } from 'react';


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Box, Button, Container, ImageList, ImageListItem, Link, Stack, Typography } from '@mui/material';
import invoice_img from '../../assets/invoce-temp.png';
import files_image from '../../assets/files-temp.png';
import earnings_image from '../../assets/total_earnings.png';


const Home = (props) => {

    return (
        <Container maxWidth={false} disableGutters>
            <Box sx={{ backgroundColor: 'background.dark', color: 'white', p: 10 }}>
                <Container maxWidth='md' sx={{ textAlign: 'center' }}>
                    <Typography variant='h1' color='white'>
                        A File Sharing & Payment Processing Tool For Creative Professionals
                    </Typography>
                    <Typography sx={{mt: 2}}>One link for your clients to pay and download files</Typography>
                    <Typography>Files are locked until you get paid</Typography>

                    <Button component={Link} sx={{ width: "50%", mt: 4, borderRadius: 3, ':hover': { color: 'white' } }} variant="contained" size="large" href='/register'>Sign Up</Button>
                </Container>
            </Box>
            <Grid container maxWidth='md' spacing={6} sx={{ mx: 'auto', my: 8 }}>
                <Grid item xs={6}>
                    <Typography variant='h2'>Invoicing</Typography>
                    <Typography>Create professional invoices that precisely reflect your pricing and payment preferences.</Typography>
                    <ul>
                        <li>Set your price</li>
                        <li>Select your payment methods</li>
                        <li>Professional invoices generated by Stripe</li>
                    </ul>
                </Grid>
                <Grid item xs={6}>
                    <Box
                        component="img"
                        src={invoice_img}
                        sx={{ borderRadius: 2, width: '100%', boxShadow: 3 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Box
                        component="img"
                        src={files_image}
                        sx={{ borderRadius: 2, width: '100%', boxShadow: 3 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='h2'>File Sharing</Typography>
                    <Typography>Upload files to share with you client.</Typography>
                    <ul>
                        <li>One link to for your client to pay and receive your work</li>
                        <li>Locked files until payment is received</li>
                        <li>Image previews</li>
                    </ul>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='h2'>Payment Processing</Typography>
                    <Typography>Secure payment processing with Stripe.</Typography>
                    <Typography>Track your earning.</Typography>
                    <Typography>Get notified as soon as a client pays.</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box
                        component="img"
                        src={earnings_image}
                        sx={{ borderRadius: 2, width: '100%', boxShadow: 3 }}
                    />
                </Grid>
            </Grid>
            <Container maxWidth='md' sx={{ textAlign: 'center', p: 4 }}>
                <Typography variant='h2'>
                    Don't miss out on efficient file and invoice sharing!
                </Typography>
                <Button component={Link} sx={{ width: "50%", mt: 2, borderRadius: 3, ':hover': { color: 'white' } }} variant="contained" size="large" href='/register'>Sign Up</Button>
            </Container>
        </Container >
    );
}
export default Home;