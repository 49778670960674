import React, { useState } from 'react';

import Paper from '@mui/material/Paper';

import { getOrders } from '../../api/Midl';
import AccountInfo from './AccountInfo';
import CreateOrderModal from './CreateOrderModal';
import OrdersTable from './OrdersTable';


const Dashboard = (props) => {
    const [orders, setOrders] = useState([]);

    React.useEffect(() => {
        getOrders()
            .then((orders) => {
                setOrders(orders);
            });
    }, []);

    return (
        <Paper elevation={0} sx={{ margin: '1em auto', maxWidth: 800, padding: '1em' }}>
            <AccountInfo />
            <h1 style={{ float: 'left' }}>My Orders</h1>
            <CreateOrderModal />
            <OrdersTable orders={orders} />
        </Paper>
    );
}
export default Dashboard;