import React from 'react';

import { Container, CircularProgress, Typography } from '@mui/material';

import { getAccount, getStripeLoginLink, getStripeAccountLink } from '../../api/Midl';


const StripeRedirect = ({ orders, ...props }) => {

    const handleViewStripeAccount = (event, order) => {
        getStripeLoginLink().then((resp) => {
            window.location.href = resp.url;
        });
    };

    const handleStripeOnboarding = (event, order) => {
        getStripeAccountLink().then((resp) => {
            window.location.href = resp.url;
        });
    };

    React.useEffect(() => {
        getAccount()
            .then((resp) => {
                if (!!!resp.stripe_onboarding_complete) {
                    handleStripeOnboarding();
                } else {
                    handleViewStripeAccount();
                }
            });
    }, []);

    return (
        <Container maxWidth='xl' sx={{ margin: '3em auto', textAlign: 'center' }}>
            <CircularProgress size="3rem" />
            <Typography variant='h2'>
                Redirecting to Stripe...
            </Typography>
        </Container>
    );
}

export default StripeRedirect;