import React from 'react';
import { useNavigate } from 'react-router-dom';

import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


const statusColor = {
    Draft: 'default',
    Open: 'primary',
    Accepted: 'success',
};

const OrdersTable = ({ orders, ...props }) => {
    const navigate = useNavigate();

    const handleRowClick = (event, order) => {
        return navigate(`/orders/${order.id}`);
    }

    return (
        <TableContainer elevation={2} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders.map((order) => (
                        <TableRow
                            key={order.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            onClick={(event) => handleRowClick(event, order)}
                            hover
                        >
                            <TableCell component='th' scope='row'>
                                {order.id}
                            </TableCell>
                            <TableCell>{order.name}</TableCell>
                            <TableCell>{order.customer ? (order.customer.name ? order.customer.name : order.customer.email) : ''}</TableCell>
                            <TableCell><Chip label={order.status} color={statusColor[order.status]} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default OrdersTable;