import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { verify } from '../../api/Auth';

import {
    Alert,
    CircularProgress,
    Container,
    Typography
} from '@mui/material';


const VerifyEmail = (props) => {
    const [searchParams] = useSearchParams();

    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState("");

    React.useEffect(() => {
        const email = searchParams.get('email');
        const token = searchParams.get('token');
        setLoading(true);
        verify(email, token)
            .then(() => {
                setSuccess(true);
            }).catch((err) => {
                setError(err);
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <Container maxWidth='xs' sx={{ margin: '3em auto', textAlign: 'center' }}>
            {loading &&
                <Container sx={{ margin: '1em auto', padding: '1em', maxWidth: '600px', textAlign: 'center' }}>
                    <CircularProgress sx={{ display: 'block', margin: 'auto' }} size={100} />
                    <h1>Loading...</h1>
                </Container>
            }
            {success &&
                <>
                    <Alert severity='success'>Your email has been verified.</Alert>
                    <Typography variant="h3">Thank you for signing up, we'll be in touch shortly.</Typography>
                </>
            }
            {error &&
                <>
                    <Alert severity='error'>{Object.values(error.response.data)}</Alert>
                    <Typography variant="h3">Failed to verify email.</Typography>
                </>
            }
        </Container>
    )
}

export default VerifyEmail;