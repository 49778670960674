import React from 'react';

import { Container, Link, Typography, Box, Stack } from '@mui/material';


const footer = () => {
    return (
        <Box sx={{ backgroundColor: 'background.dark', p: 4, textAlign: 'center' }}>
            <Stack spacing={0.5} >
                <Typography variant='h2' color='white'>Contact Us</Typography>
                <Typography variant='h3' color='white'>support@midlpay.com</Typography>
            </Stack>
            <Stack color='white' direction="row" spacing={4} sx={{ mx: 'auto', mt: 4, display: 'block' }}>
                <Link
                    href='/terms-of-service'
                    underline='none'
                    color='inherit'
                    sx={
                        {
                            ":hover": {
                                color: "#6935D3",
                            },
                        }}>
                    Terms of Service
                </Link>
                <Link
                    href='/privacy-policy'
                    underline='none'
                    color='inherit'
                    sx={
                        {
                            ":hover": {
                                color: "#6935D3",
                            },
                        }}
                >
                    Privacy Policy
                </Link>
            </Stack>
            <Typography color='white' sx={{ fontSize: 12, mt: 1 }}>© 2023 Midlpay. All rights reserved.</Typography>
        </Box>
    );
}

export default footer;