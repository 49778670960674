import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Container } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { getOrder } from '../../api/Midl';
import OrderEdit from './OrderEdit';
import OrderView from './OrderView';
import CreateOrder from './CreateOrder';

const OrderDetails = (props) => {
    const params = useParams();
    const [order, setOrder] = useState(null);

    React.useEffect(() => {
        getOrder(params.id)
            .then((orderData) => {
                setOrder(orderData);
            });
    }, []);

    if (!order) {
        return (
            <Container sx={{ margin: '1em auto', padding: '1em', maxWidth: '600px', textAlign: 'center' }}>
                <CircularProgress sx={{ display: 'block', margin: 'auto' }} size={100} />
                <h1>Loading...</h1>
            </Container>);
    } else if (order.status === 'Draft') {
        return <CreateOrder order={order} setOrder={setOrder} />;
    } else {
        return <OrderView order={order} />;
    }
}
export default OrderDetails;