import React, { useState } from 'react';

import Container from '@mui/material/Container';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import AddFiles from './AddFiles';
import InvoiceDetails from './InvoiceDetails';
import Review from './Review';
import Send from './Send';

const steps = [
    'Create invoice',
    'Add files',
    'Review',
    'Send to customer',
];

const CreateOrder = ({ order, setOrder, ...props }) => {
    const [activeStep, setActiveStep] = useState(1);

    var stepBody = null;
    switch (activeStep) {
        case 0:
            stepBody = <InvoiceDetails order={order} setOrder={setOrder} setActiveStep={setActiveStep} />;
            break;
        case 1:
            stepBody = <AddFiles order={order} setOrder={setOrder} setActiveStep={setActiveStep} />;
            break;
        case 2:
            stepBody = <Review order={order} setOrder={setOrder} setActiveStep={setActiveStep} />;
            break;
        case 3:
            stepBody = <Send order={order} setOrder={setOrder} setActiveStep={setActiveStep} />;
            break;
    }

    return (
        <Container maxWidth='xl' sx={{ p: 8 }}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 2, mx: 'auto', maxWidth: 'md' }}>
                {steps.map((label, index) => (
                    <Step key={label} onClick={() => { setActiveStep(index) }}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {stepBody}
        </Container>
    );
}
export default CreateOrder;