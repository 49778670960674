import React, { useState } from 'react';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dropzone from 'react-dropzone';
import { NotificationManager } from 'react-notifications';

import {
    Box, FormControl, Stack, Typography, LinearProgress
} from '@mui/material';

import { uploadFile } from '../../api/Midl';

const FileUpload = ({ addFiles, ...props }) => {
    const [uploading, setUploading] = useState(false);

    const handleAddNewFiles = (addedFiles) => {
        setUploading(true);
        const fileUpload = [];
        addedFiles.forEach(file => {
            const fileData = {
                file: file
            }
            fileUpload.push(uploadFile(fileData))
        });
        Promise.all(fileUpload).then((resp) => {
            NotificationManager.success(`Successfully uploaded: ${addedFiles.length} files`, 'Files Uploaded!', 2000);
            addFiles(resp);
            setUploading(false);
        });
    };
    return (
        <FormControl fullWidth sx={{ ...props.sx }}>
            <Dropzone onDrop={handleAddNewFiles}>
                {({ getRootProps, getInputProps }) => (
                    <Box {...getRootProps()}
                        sx={{
                            backgroundColor: '#F6F6F6',
                            border: '2px dashed #201F25',
                            borderRadius: 2,
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <input name='file' {...getInputProps()} />
                        <Stack alignItems="center" spacing={1} sx={{ p: 1, m: 'auto' }}>
                            <Typography textAlign='center' color="black">Drag 'n' drop files here<br />or click to select files</Typography>
                            <CloudUploadIcon color="black" fontSize="large" />
                        </Stack>
                    </Box>
                )}
            </Dropzone>
            {uploading && <LinearProgress sx={{ mt: 1 }} />}
        </FormControl>
    );
}

export default FileUpload;
