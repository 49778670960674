import React, { useState } from 'react';

import {
    Button,
    Container,
    Typography
} from '@mui/material';

import { formatCurrency } from '../../util/TextFormatters'
import FileDisplay from '../../common/FileDisplay';



const Review = ({ order, setActiveStep, ...props }) => {

    return (
        <Container>
            <Typography variant='h2' sx={{ textAlign: 'center', mt: 4 }}>{order.name}</Typography>
            <Typography variant='h3' sx={{ textAlign: 'center', mt: 1 }}>{formatCurrency(order.amount)}</Typography>
            <FileDisplay
                files={order.files}
            />
            <Button fullWidth variant='contained' onClick={() => { setActiveStep(3) }} sx={{ mt: 4 }}>Continue</Button>
            <Button fullWidth sx={{ mt: 1 }} onClick={() => { setActiveStep(1) }}>Back</Button>
        </Container>
    );
}
export default Review;