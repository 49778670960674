import React, { useState } from 'react';

import Carousel from 'react-material-ui-carousel';
import FilePreview from '../order-details/FilePreview';
import FilesList from '../order-details/FilesList';


const FileDisplay = ({ files, updateOrder, canEdit, preview, secondaryAction, ...props }) => {
    const [selectedFile, setSelectedFile] = useState(0);
    return (
        <div>
            <Carousel autoPlay={false} animation='slide' navButtonsAlwaysVisible={true} index={selectedFile} onChange={(now) => setSelectedFile(now)}>
                {
                    files.map((file) => <FilePreview file_url={preview ? file.preview_url : file.url} />)
                }
            </Carousel>
            <FilesList files={files} setSelectedFile={setSelectedFile} selectedFile={selectedFile} secondaryAction={secondaryAction} />
        </div>
    );
}

export default FileDisplay;
