import React from 'react';
import { NotificationContainer } from 'react-notifications';
import { Route, Routes } from 'react-router-dom';

import { Container, Box, CssBaseline } from '@mui/material';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

import About from './components/about';
import TermsOfService from './components/terms-of-service';
import PrivacyPolicy from './components/privacy-policy';
import Checkout from './components/checkout';
import Dashboard from './components/dashboard';
import Footer from './components/footer';
import ForgotPassword from './components/forgot-password';
import Home from './components/home';
import Login from './components/login/Login';
import Navbar from './components/nav-bar';
import NotFound from './components/not-found';
import Register from './components/register';
import OrderDetails from './components/order-details';
import PasswordReset from './components/password-reset';
import Onboarding from './components/onboarding';
import OnboardedRoute from './components/util/OnboardedRoute';
import ProtectedRoute from './components/util/ProtectedRoute';
import StripeRedirect from './components/util/StripeRedirect';
import VerifyEmail from './components/verify-email';


let theme = createTheme({
  palette: {
    primary: {
      main: '#6935D3',
    },
    secondary: {
      main: '#FFFFFF',
    },
    background: {
      default: '#FFFFFF',
      dark: '#201F25',
      secondary: '#585858'
    },
    stripe: {
      main: '#625AF9'
    }
  },
  typography: {
    h1: {
      fontSize: 48,
      fontWeight: 400,
      fontFamily: "Helvetica",
      color: 'black'
    },
    h2: {
      fontSize: 32,
      fontWeight: 400,
      fontFamily: "Helvetica",
      color: 'black'
    },
    h3: {
      fontSize: 24,
      fontWeight: 300,
      fontFamily: "Helvetica",
      color: 'black'
    },
    h4: {
      fontSize: 24,
      fontWeight: 400,
      fontFamily: "Helvetica",
      color: 'black'
    },
    h5: {
      fontSize: 18,
      fontWeight: 500,
      fontFamily: "Helvetica",
      color: 'black'
    }
  }
});

theme = responsiveFontSizes(theme);

const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NotificationContainer />
      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Navbar />
        <main style={{ flex: 1 }}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/contact' element={<Home />} />
            <Route path='/terms-of-service' element={<TermsOfService />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/checkout/:id' element={<Checkout />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/forgot' element={<ForgotPassword />} />
            <Route path='/verify' element={<VerifyEmail />} />
            <Route path='/password_reset' element={<PasswordReset />} />
            <Route path='/dashboard' one element={
              <ProtectedRoute>
                <OnboardedRoute>
                  <Dashboard />
                </OnboardedRoute>
              </ProtectedRoute>
            } />
            <Route path='/stripe_account' one element={
              <ProtectedRoute>
                <StripeRedirect />
              </ProtectedRoute>
            } />
            <Route path='/orders/:id' element={
              <ProtectedRoute>
                <OnboardedRoute>
                  <OrderDetails />
                </OnboardedRoute>
              </ProtectedRoute>
            } />
            <Route path='/onboarding' element={
              <ProtectedRoute>
                <Onboarding />
              </ProtectedRoute>
            } />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </ThemeProvider >
  );
}

export default App;
