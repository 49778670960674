import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { createOrder } from '../../api/Midl';

const CreateOrderModal = (props) => {
    const navigate = useNavigate();

    const handleCreateOrder = (event) => {
        event.preventDefault();
        createOrder({})
            .then((order) => {
                navigate(`/orders/${order.id}`);
            });
    }

    return (
        <>
            <Button style={{ float: 'right' }} variant='contained' onClick={handleCreateOrder}>New Order</Button>
        </>
    );
}

export default CreateOrderModal;
