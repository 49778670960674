import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { getAccount } from '../../api/Midl';


const AccountInfo = ({ orders, ...props }) => {
    const [account, setAccount] = useState([]);

    React.useEffect(() => {
        getAccount()
            .then((resp) => {
                setAccount(resp);
            });
    }, []);

    return (
        <Box
            sx={{ marginBottom: '1em' }}
        >
            <h1 style={{ marginBottom: 0 }}>{account.stripe_name}</h1>
            <Link
                variant='body2'
                underline='none'
                href='/stripe_account'
            >
                View Stripe account
            </Link>
        </Box>
    );
}

export default AccountInfo;