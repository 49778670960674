import React, { useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import {
    IconButton, Box
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';

import FileUpload from '../FileUpload';
import FilePreview from '../FilePreview';
import AddFilesList from '../AddFilesList';



const AddFilesComponent = ({ files, updateOrder, ...props }) => {
    const [selectedFile, setSelectedFile] = useState(0);

    const addFiles = (filesToAdd) => {
        var newFiles = filesToAdd.filter(o1 => {
            return !files.find(o2 => {
                return o1.id === o2.id
            })
        })
        updateOrder('files', [...new Set([...files, ...newFiles])]);
    }

    const removeFile = (event, file) => {
        const updatedFiles = files.filter((f) => {
            return f.id !== file.id;
        });
        updateOrder('files', updatedFiles);
        if (updatedFiles.length >= selectedFile) {
            setSelectedFile(updatedFiles.length - 1);
        }
    }

    if (files.length === 0) {
        return (<FileUpload addFiles={addFiles} sx={{ height: '500px' }} />);
    }

    return (
        <Box sx={{ display: 'flex' }} >
            <Box sx={{ flex: 3 }}>
                <Carousel
                    autoPlay={false}
                    animation='slide'
                    navButtonsAlwaysVisible={true}
                    index={selectedFile}
                    onChange={(now) => setSelectedFile(now)}
                >
                    {
                        files.map((file) => <FilePreview key={file.id} file_url={file.url} />)
                    }
                </Carousel>
            </Box>
            <AddFilesList
                files={files}
                setSelectedFile={setSelectedFile}
                selectedFile={selectedFile}
                canEdit={true}
                secondaryAction={(file) => {
                    return (<IconButton edge='end' aria-label='delete' onClick={(event) => removeFile(event, file)}>
                        <ClearIcon />
                    </IconButton>);
                }}
                addFiles={addFiles}
                sx={{ flex: 1, minWidth: '200px' }}
            />
        </Box>
    );
}
export default AddFilesComponent;
