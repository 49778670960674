import React from 'react';
import { NotificationManager } from 'react-notifications';


import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';

import { createCustomer, getCustomers } from '../../api/Midl';

const filter = createFilterOptions({ stringify: (option) => (option.name + option.email) || '' });

const CustomerCreateOptionDialog = ({ value, onChange }) => {
    const [open, toggleOpen] = React.useState(false);
    const [customers, setCustomers] = React.useState([]);

    const handleClose = () => {
        setDialogValue({
            name: '',
            email: '',
        });
        toggleOpen(false);
    };

    const [dialogValue, setDialogValue] = React.useState({
        name: '',
        email: '',
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        createCustomer(dialogValue)
            .then((response) => {
                NotificationManager.success('Added customer.', 'Success!', 2000);
                onChange(response);
                loadCustomers();
                handleClose();
            });
    };

    const loadCustomers = () => {
        getCustomers()
            .then((response) => {
                setCustomers(response.customers);
            });
    }

    React.useEffect(() => {
        loadCustomers();
    }, []);

    return (
        <React.Fragment>
            <Autocomplete
                id='customer'
                size='small'
                value={value}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                            toggleOpen(true);
                        });
                    } else if (newValue && newValue.inputValue) {
                        toggleOpen(true);
                    } else {
                        onChange(newValue);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            id: 'add-customer-option',
                            name: `Add '${params.inputValue}'`,
                        });
                    }

                    return filtered;
                }}
                options={customers}
                getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.email || '';
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) =>
                    <ListItem {...props} key={option.id}>
                        <ListItemText primary={option.name} secondary={option.email} />
                    </ListItem>}
                freeSolo
                renderInput={(params) => <TextField name='customer' variant='standard' margin='normal' label='Customer' {...params} />}
            />
            <Dialog open={open} onClose={handleClose}>
                <form onSubmit={handleSubmit} id='add-customer'>
                    <DialogTitle>Add New Customer</DialogTitle>
                    <DialogContent>
                        <TextField
                            id='name'
                            variant='standard'
                            margin='dense'
                            autoFocus
                            fullWidth
                            value={dialogValue.name}
                            onChange={(event) =>
                                setDialogValue({
                                    ...dialogValue,
                                    name: event.target.value,
                                })
                            }
                            label='Name'
                            type='text'
                            helperText="This name will appear in email's to the customer."
                        />
                        <TextField
                            id='email'
                            variant='standard'
                            margin='dense'
                            fullWidth
                            value={dialogValue.email}
                            onChange={(event) =>
                                setDialogValue({
                                    ...dialogValue,
                                    email: event.target.value,
                                })
                            }
                            label='Email'
                            type='email'
                            helperText="The email to which invoice and payment link will be sent."
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type='submit' form='add-customer' >Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    );
}

export default CustomerCreateOptionDialog;