import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getIsLoggedIn } from '../../api/Auth';


const ProtectedRoute = (props) => {
    const navigate = useNavigate();
    const [isLoggedIn] = useState(getIsLoggedIn());

    useEffect(() => {
        if (!!!isLoggedIn) {
            return navigate('/login')
        }
    }, [isLoggedIn]);

    return (
        <>
            {
                isLoggedIn ? props.children : null
            }
        </>
    );
}
export default ProtectedRoute;